<template>
  <div class="notFound">
    <div class="cover" :style="{ marginBottom: coverMarginBottom }">
      <Header
        :title="$t('notFound.title')"
        :subtitle="$t('notFound.subtitle')"
        colorRotation="120"
        ref="header"
      >
      </Header>

      <Section>
        <b-row>
          <b-col sm="9">
            <p v-html="$t('notFound.intro.text')" style="margin-bottom: 0"></p>
          </b-col>
        </b-row>
      </Section>

      <Section>
        <h3> {{ $t('notFound.suggestions.heading') }} </h3>

        <Shelf :items="$router.options.routes.filter(route => route.path !== '*')">
          <template v-slot:item="{ item }">
            <Card :link="item.path + `?lang=${$i18n.locale}`" replace>
              <template #header>
                <Cover :aspectRatio="4/3" :assetUrl="item.thumb"/>
              </template>
              <h5> erikvanderplas.com{{ item.path }} </h5>
              <h4 style="margin-bottom: .5rem"> {{ item.meta().title }} </h4>
              <p v-html="item.meta().metaTags[0].content" class="limited"></p>
            </Card>
          </template>
        </Shelf>
      </Section>

      <div style="height: 6rem"></div>
    </div>
    <Footer @heightChanged="coverMarginBottom = $event + 'px'"/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Section from '@/components/Section.vue';
import Shelf from '@/components/Shelf.vue';
import Card from '@/components/Card.vue';
import Cover from '@/components/Cover.vue';
import Footer from '@/components/Footer.vue';
import parallax from '../parallax';

export default {
  name: 'home',
  components: {
    Header,
    Section,
    Shelf,
    Card,
    Cover,
    Footer,
  },
  data: () => ({
    coverMarginBottom: '0px',
  }),
  mounted() {
    parallax.setup(this.$refs.header);
    parallax.add(this.$refs.header.$refs.text, offset => offset * 0.2);
    this.$refs.header.$refs.text.classList.add('slidesInUp');
  },
};
</script>

<style scoped lang="scss">

.cover {
  background-color: white;
  position: relative;
  z-index: 1;
}

p.limited {
  $num-lines: 4;
  height: calc(1.5rem * #{$num-lines});
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $num-lines;
  -webkit-box-orient: vertical;
}

</style>
